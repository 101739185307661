import Loaders from '../../../loaders'
import { Await, useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast, ToastContainer } from 'react-toastify'
import classes from '../../../common.module.css'
import cls from './Stories.module.css'
import DELETE from '../../../assets/delete.svg'
import TOP from '../../../assets/top.svg'
import DOWN from '../../../assets/down.svg'
const videoTypes = ['mp4', 'webm']
const getComponent = (slide) => {
    const slideType = slide?.split('.')?.at(-1)
    if (videoTypes.includes(slideType)) {
        return {
            contentType: `video/${slideType}`,
            element: <video className={cls.Slide} src={slide} autoPlay={false} controls />,
        }
    }
    return {
        contentType: `image/${slideType}`,
        element: <img className={cls.Slide} src={slide} />,
    }
}
const loaders = new Loaders()
const Story = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const data = useLoaderData()

    const [story, setStory] = useState(null)

    const onDrop = useCallback(
        (acceptedFiles) => {
            loaders.stories
                .setImage(story._id, acceptedFiles[0])
                .then((data) => {
                    setValue('preview', data.imgUrl)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        [story]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
    })

    const setValue = (key, value) => {
        setStory({ ...story, [key]: value })
    }

    const addSlide = () => {
        setStory({
            ...story,
            slides: [
                ...story.slides,
                {
                    action: {
                        title: '',
                        textColor: '#ffffff',
                        backgroundColor: '#000000',
                        path: '',
                    },
                    contentType: '',
                    url: '',
                    _id: v4(),
                },
            ],
        })
    }

    const toastId = useRef(null)
    useEffect(() => {
        if (location.pathname.includes('add')) {
            loaders.stories
                .add()
                .then(({ _id }) => {
                    navigate(`/contents/stories/${_id}`)
                })
                .catch(console.error)
        }
    }, [])

    const onSave = (e) => {
        e.preventDefault()
        toastId.current = toast('Обновляем историю', { autoClose: false })
        loaders.stories
            .update({
                ...story,
                isDeleted: false,
            })
            .then(() => {
                toast.update(toastId.current, {
                    render: 'История обновлена',
                    type: toast.TYPE.INFO,
                    autoClose: 3000,
                })
            })
            .catch((err) => {
                console.log(err)
                toast.update(toastId.current, {
                    render: 'Не удалось обновить историю',
                    type: toast.TYPE.ERROR,
                    autoClose: 3000,
                })
            })
    }

    const onSwap = (from, to) => {
        const newSlides = [...story.slides]
        const removed = newSlides.splice(from, 1)
        newSlides.splice(to, 0, removed[0])
        setValue('slides', newSlides)
    }
    return story || location.pathname.includes('add') ? (
        <>
            <ToastContainer />
            <h4>Название</h4>
            <input
                type={'text'}
                value={story ? story.name : ''}
                onChange={({ target: { value } }) => setValue('name', value)}
            />
            <br />
            <h4>Превью</h4>
            <img src={story && story.preview} alt={story && story.name} className={cls.Preview} />
            <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Перетащите файл сюда</p>
                ) : (
                    <p>Перетащите файл или кликните на область, чтобы выбрать</p>
                )}
            </div>
            <br />
            <h4>Слайды</h4>
            <div className={cls.Slides}>
                {story?.slides?.map((slide, index) => {
                    return (
                        <SlideItem
                            key={slide._id}
                            storyId={story._id}
                            slide={slide}
                            index={index + 1}
                            length={story.slides.length}
                            swap={onSwap}
                            onRemove={(id) =>
                                setStory({
                                    ...story,
                                    slides: story.slides.filter((s) => s._id !== id),
                                })
                            }
                            onChange={(_slide) =>
                                setStory({
                                    ...story,
                                    slides: story.slides.map((s) =>
                                        s._id === _slide._id ? _slide : s
                                    ),
                                })
                            }
                        />
                    )
                })}
                <div className={cls.SlideAdd} onClick={addSlide}>
                    + Добавить
                </div>
            </div>
            <br />
            <button onClick={onSave} className={classes.btn}>
                Сохранить
            </button>
        </>
    ) : (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {({ data }) => {
                    Promise.resolve(data).then((s) => setStory(s[0]))
                }}
            </Await>
        </Suspense>
    )
}
const SlideItem = ({ storyId, slide, onChange, index, onRemove, length, swap }) => {
    const { contentType, element } = getComponent(slide.url)
    const onDrop = useCallback(
        (acceptedFiles) => {
            loaders.stories
                .setImage(storyId, acceptedFiles[0])
                .then((data) => {
                    onChange({
                        ...slide,
                        url: data.imgUrl,
                        contentType: getComponent(data.imgUrl).contentType,
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        [storyId]
    )
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
    })

    return (
        <div className={cls.SlidesItem} key={slide._id}>
            <div className={cls.SlidesItemHeader}>
                <div>
                    <button
                        onClick={() => swap(index - 1, index - 2)}
                        className={cls.BtnNav}
                        disabled={index === 1}>
                        <img src={TOP} />
                    </button>
                    <span>{index}</span>
                    <button
                        onClick={() => swap(index - 1, index)}
                        className={cls.BtnNav}
                        disabled={index === length}>
                        <img src={DOWN} />
                    </button>
                </div>
                <button className={cls.RemoveBtn} onClick={() => onRemove(slide._id)}>
                    <img src={DELETE} />
                </button>
            </div>
            <div className={cls.SlidesItemContent}>
                <div>{element}</div>
                <div {...getRootProps()} className={classes.dropzone}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Перетащите файл сюда</p>
                    ) : (
                        <p>Перетащите файл или кликните на область, чтобы выбрать</p>
                    )}
                </div>
            </div>
            <div className={cls.SlidesItemAction}>
                <div>
                    <label>Название</label>
                    <input
                        value={slide.action.title || ''}
                        placeholder='Title'
                        onChange={({ target }) =>
                            onChange({
                                ...slide,
                                action: {
                                    ...slide.action,
                                    title: target.value,
                                },
                            })
                        }
                    />
                </div>
                <div>
                    <label>Ссылка</label>
                    <input
                        value={slide.action.path || ''}
                        onChange={({ target }) =>
                            onChange({
                                ...slide,
                                action: {
                                    ...slide.action,
                                    path: target.value,
                                },
                            })
                        }
                    />
                </div>
                <div>
                    <label>Цвет текста</label>
                    <input
                        type={'color'}
                        value={slide.action.textColor || '#fff'}
                        onChange={({ target }) =>
                            onChange({
                                ...slide,
                                action: {
                                    ...slide.action,
                                    textColor: target.value,
                                },
                            })
                        }
                    />
                </div>
                <div>
                    <label>Цвет фона</label>
                    <input
                        type={'color'}
                        value={slide.action.backgroundColor || '#fff'}
                        onChange={({ target }) =>
                            onChange({
                                ...slide,
                                action: {
                                    ...slide.action,
                                    backgroundColor: target.value,
                                },
                            })
                        }
                    />
                </div>
            </div>
        </div>
    )
}
export default Story
