import Loaders from '../../../loaders'
import { Await, useLoaderData, useLocation, useNavigate } from 'react-router-dom'
import { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast, ToastContainer } from 'react-toastify'
import { Editor } from 'react-draft-wysiwyg'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import classes from '../../../common.module.css'
import draftToHtml from 'draftjs-to-html'

const Event = () => {
    const loaders = new Loaders()
    const location = useLocation()
    const navigate = useNavigate()
    const data = useLoaderData()

    const [editor, setEditor] = useState(EditorState.createEmpty())
    const [event, setEvent] = useState(null)

    const toastId = useRef(null)

    useEffect(() => {
        if (location.pathname.includes('add')) {
            loaders.events
                .add()
                .then(({ _id }) => {
                    navigate(`/contents/events/${_id}`)
                })
                .catch(console.error)
        }
    }, [])

    useEffect(() => {
        if (event && event.content)
            setEditor(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(convertFromHTML(event.content))
                )
            )
    }, [event])

    const onDrop = useCallback(
        (acceptedFiles) => {
            loaders.events
                .setImage(event._id, acceptedFiles[0])
                .then((data) => {
                    setValue('image', data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        [event]
    )

    const setValue = (key, value) => {
        setEvent({ ...event, [key]: value })
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
    })

    const onSave = (e) => {
        e.preventDefault()
        toastId.current = toast('Обновляем акцию', { autoClose: false })
        event.content = draftToHtml(convertToRaw(editor.getCurrentContent()))
        loaders.events
            .update(event)
            .then(() => {
                toast.update(toastId.current, {
                    render: 'Акция обновлена',
                    type: toast.TYPE.INFO,
                    autoClose: 3000,
                })
            })
            .catch((err) => {
                console.log(err)
                toast.update(toastId.current, {
                    render: 'Не удалось обновить акцию',
                    type: toast.TYPE.ERROR,
                    autoClose: 3000,
                })
            })
    }

    return event || location.pathname.includes('add') ? (
        <>
            <ToastContainer />
            <h4>Название</h4>
            <input
                type='text'
                value={event ? event.title : ''}
                onChange={({ target: { value } }) => setValue('title', value)}
            />
            <br />
            <h4>Подзаголовок</h4>
            <input
                type='text'
                value={event ? event.desc : ''}
                onChange={({ target: { value } }) => setValue('desc', value)}
            />
            <br />
            <h4>Время действия акции</h4>
            <small>Оставьте поле "Конец" пустым, если акция бессрочна</small>
            <div className={classes.dRow}>
                <input type='text' placeholder='Начало' />
                <span>&emsp;&ndash;&emsp;</span>
                <input type='text' placeholder='Конец' />
            </div>
            <br />
            <img src={event && event.image} alt={event && event.title} className={classes.img} />
            <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Перетащите файл сюда</p>
                ) : (
                    <p>Перетащите файл или кликните на область, чтобы выбрать</p>
                )}
            </div>
            <br />

            <h4>Описание</h4>
            <Editor
                editorState={editor}
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
                editorClassName='editorClassName'
                localization={{
                    locale: 'ru',
                }}
                onEditorStateChange={(e) => setEditor(e)}
            />
            <br />
            <button onClick={onSave} className={classes.btn}>
                Сохранить
            </button>
        </>
    ) : (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {({ data }) => {
                    Promise.resolve(data).then((s) => setEvent(s[0]))
                }}
            </Await>
        </Suspense>
    )
}

export default Event
