import DeleteImg from '../../assets/x-mark.png'
import classes from './Button.module.css'

const DeleteButton = ({ id, action, complete, isDeleted, onDeleteByFunction }) => {
    const onClick = (e) => {
        e.preventDefault()
        console.log(action)
        if (onDeleteByFunction) {
            onDeleteByFunction()
        } else {
            action(id, !isDeleted)
                .then((data) => {
                    console.log(data)
                    complete(true)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <button className={classes.btnImage} onClick={onClick}>
            <img src={DeleteImg} alt='Удалить' />
        </button>
    )
}

export default DeleteButton
