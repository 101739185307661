import { Suspense, useState } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Table from '../../../Components/Table'
import Loaders from '../../../loaders/loaders'
import { getMinPrice } from './utils'
import saveImg from '../../../assets/save.svg'
import editImg from '../../../assets/edit.svg'
const Products = () => {
    const data = useLoaderData()
    const loaders = new Loaders()

    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {(res) => (
                    <Table
                        api={loaders.products}
                        withSearch
                        withPagination
                        columns={columns}
                        data={res}
                        path='/store/products'
                        rowModifierFunc={(products) =>
                            products.map((p) => ({
                                ...p,
                                calculatedPrice: getMinPrice(p),
                                position: <PositionProduct id={p._id} order={0} />,
                            }))
                        }
                    />
                )}
            </Await>
        </Suspense>
    )
}

const PositionProduct = ({ id, order }) => {
    const [isEditing, setEditing] = useState(false)
    const [_order, setOrder] = useState(order)
    const onSave = () => {
        setEditing(false)
    }
    return (
        <div onClick={(e) => e.stopPropagation()} style={{ display: 'flex', flexDirection: 'row' }}>
            <input
                type={'number'}
                min={1}
                readOnly={!isEditing}
                value={order}
                onChange={(e) => setOrder(e.target.value)}
            />
            {isEditing ? (
                <img style={{ width: '1rem' }} src={saveImg} onClick={onSave} />
            ) : (
                <img style={{ width: '1rem' }} src={editImg} onClick={() => setEditing(true)} />
            )}
        </div>
    )
}

const columns = [
    {
        name: '№',
        field: 'position',
    },
    {
        name: 'Артикул',
        field: 'article',
    },
    {
        name: 'Название',
        field: 'name',
    },
    {
        name: 'Цена',
        field: 'calculatedPrice',
        type: 'price',
    },
    {
        name: 'Дата обновления',
        field: 'updatedAt',
        type: 'date',
    },
]

export default Products
