import Page from "../../Components/Page"
import { Header } from "../../Components/Contetnt"
import { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

const Store = () => {

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (location.pathname === "/store") {
            navigate("/store/categories")
        }
    }, [location])

    return (
        <Page>
            <Header title="Магазин" links={links} />
            <Outlet />
        </Page>
    )
}

const links = [
    {
        link: "categories",
        name: "Категории"
    },
    {
        link: "products",
        name: "Товары"
    },
    {
        link: "tags",
        name: "Теги"
    },
    {
        link: "brands",
        name: "Бренды"
    },
    {
        link: "compilations",
        name: "Подборки"
    },
]

export default Store