import { Suspense, useState } from 'react'
import Table from '../../../Components/Table'
import Button from '../../../Components/Button'
import { Await, useLoaderData } from 'react-router-dom'
import Loaders from '../../../loaders'

const Tags = () => {
    const tags = useLoaderData()
    const loaders = new Loaders()
    const [removed, setRemoved] = useState([])
    const removeTag = (tag) => {
        loaders.tagsGroup
            .delete(tag._id, true)
            .then((res) => {
                console.log(res)
                setRemoved((prev) => [...prev, tag._id])
            })
            .catch((e) => {
                console.log(e)
            })
    }
    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={tags} errorElement={<p>Error</p>}>
                {(res) => (
                    <Table
                        // api={loaders.tagsGroup}
                        withSearch
                        withButtons={buttons}
                        columns={columns}
                        data={res}
                        removed={removed}
                        onDelete={removeTag}
                        path='/store/tags'
                    />
                )}
            </Await>
        </Suspense>
    )
}

const buttons = [
    <Button key='btn_add_tag_group' name='Добавить группу' link='add/group' />,
    <Button key='btn_add_tag' name='Добавить тэг' link='add/tag' />,
]

const columns = [
    {
        name: 'Название',
        field: 'name',
    },
    {
        name: 'Дата создания',
        field: 'updatedAt',
        type: 'date',
    },
]

const actions = [
    {
        name: 'Выключить',
        image: '',
    },
    {
        name: 'Удалить',
        image: '',
    },
]

export default Tags
