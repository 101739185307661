import {Suspense, useState} from "react"
import Loaders from "../../../loaders"
import Select from "react-select"
import {Await, useLoaderData} from "react-router-dom"

const Add = () => {

    const data = useLoaderData()
    const loaders = new Loaders()
    const [name, setName] = useState("")
    const [group, setGroup] = useState("")
    const [groups, setGroups] = useState(null)

    const onSubmit = (e) => {
        e.preventDefault()
        loaders.tags.add(name, group).then((data) => {
            console.log(data)
            setName("")
        }).catch((err) => {
            console.log(err)
        })
    }

    const onTagsChange = ({value}) => {
        setGroup(value)
    }

    const prepareValues = (b, func) => {
        let bs = []
        for (let i of b) {
            bs.push({value: i._id, label: i.name})
        }

        func(bs)
    }

    return groups === null ? (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {
                    ({ tags }) => {
                        Promise.resolve(tags).then((b) => prepareValues(b, setGroups))
                    }
                }
            </Await>
        </Suspense>
    ) : (
        <form onSubmit={ onSubmit }>
            <input type="text" value={name} onChange={({target:{value}}) => setName(value)} placeholder="Название тега" required />
            <h4>Группа тэгов</h4>
            <Select onChange={onTagsChange} isSearchable isLoading={ groups.length === 0 } options={groups} />
            <button type="submit">Добавить</button>
        </form>
    )
}

export default Add