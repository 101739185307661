import {Suspense} from "react"
import {Await, useLoaderData} from "react-router-dom"
import Table from "../../../Components/Table"
import Button from "../../../Components/Button"

const Sliders = () => {

    const data = useLoaderData()

    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {
                    res => (
                        <Table withButtons={buttons} columns={columns} data={res} path="/contents/sliders" />
                    )
                }
            </Await>
        </Suspense>
    )
}

const buttons = [
    <Button key="btn_add_slider" name="Добавить слайдер" link="add" />
]

const columns = [
    {
        name: "Название",
        field: "name"
    },
    {
        name: "Дата обновления",
        field: "updatedAt",
        type: "date"
    }
]

export default Sliders