import {Outlet, useLocation} from "react-router-dom"
import Sliders from "./Sliders"

const Main = () => {

    const location = useLocation()

    return (
        <>
            {
                location.pathname === "/contents/sliders" ? (
                    <Sliders />
                ) : null
            }
            <Outlet />
        </>
    )
}

export default Main