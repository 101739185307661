import React, { Suspense, useState } from 'react'
import { Await, useLoaderData, useNavigate, useParams } from 'react-router-dom'
import Loaders from '../../loaders'
import { NotificationForm } from './NotificationForm'

export const Notification = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const pushes = useLoaderData()
    const loaders = new Loaders()
    const [push, setPush] = useState(null)
    const onSubmit = (body) => {
        loaders.notifications
            .update({
                ...body,
                _id: id,
                order: push.order,
            })
            .then((data) => {
                console.log(data)
                navigate('/notifications')
            })
            .catch((err) => console.log(err))
    }
    if (push) return <NotificationForm onSubmit={onSubmit} data={push} />
    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={pushes} errorElement={<p>Error</p>}>
                {(res) => {
                    Promise.resolve(res.data).then((d) => setPush(d[0]))
                }}
            </Await>
        </Suspense>
    )
}
