import { NavLink } from "react-router-dom"
import classes from "./Header.module.css"
import clsx from "clsx"

const Header = ({ title, links }) => {

    return (
        <div className={classes.header}>
            <h1 className={classes.header__h1}>{ title }</h1>
            <ul className={classes.header__links}>
                {
                    Array.isArray(links) && links.map((link, i) => (
                        <li key={`content_header_link_${i}`}>
                            <NavLink
                                className={({ isActive }) => clsx(classes.header__link, {[classes.header__link__active]: isActive})}
                                to={link.link}>{link.name}</NavLink>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default Header