import clsx from "clsx";
import classes from "./Header.module.css"
import common from "../../common.module.css"
import {useState} from "react"
import Loaders from "../../loaders"

const Header = () => {

    const [isUpdating, setIsUpdating] = useState(false)

    const loaders = new Loaders()

    const style = clsx({
        [classes.header]: true
    })

    const onClickUpdate = (e) => {
        e.preventDefault()
        setIsUpdating(true)
        loaders.sync.common("product")
            .then(() => {
                setIsUpdating(false)
            })
            .catch((err) => {
                console.log(err)
                setIsUpdating(false)
            })

        loaders.sync.common("bundle")
            .then(() => {

            })
            .catch((err) => {
                console.log(err)
                setIsUpdating(false)
            })
    }

    return (
        <header className={style}>
            <button className={common.btn} onClick={onClickUpdate}>
                {
                    isUpdating ? (
                        <div className="lds-dual-ring" />
                    ) : (
                        <span>Обновить товары</span>
                    )
                }
                </button>
        </header>
    )
}

export default Header