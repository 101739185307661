import Page from '../../Components/Page'
import { Header } from '../../Components/Contetnt'
import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const Contents = () => {
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (location.pathname === '/contents') {
            navigate('/contents/sliders')
        }
    }, [location])

    return (
        <Page>
            <Header title='Контент' links={links} />
            <Outlet />
        </Page>
    )
}

const links = [
    {
        link: 'sliders',
        name: 'Слайдер',
    },
    {
        link: 'stories',
        name: 'Сторис',
    },
    {
        link: 'events',
        name: 'Акции',
    },
    {
        link: 'blog',
        name: 'Новости',
    },
]

export default Contents
