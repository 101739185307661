import {Await, useLoaderData} from "react-router-dom"
import {Suspense, useCallback, useRef, useState} from "react"
import {useDropzone} from "react-dropzone"
import classes from "../../../common.module.css"
import Loaders from "../../../loaders"
import {toast, ToastContainer} from "react-toastify"

const Brand = () => {

    const data = useLoaderData()
    const [brand, setBrand] = useState(null)

    const toastId = useRef(null)

    const loaders = new Loaders()

    const onDrop = useCallback(acceptedFiles => {
        loaders.brands.setImage(brand._id, acceptedFiles[0])
            .then((data) => {
                const urls = brand.imgUrl
                urls.push(data.imgUrl)
                setBrand({...brand, imgUrl: urls})
            }).catch((err) => {
                console.log(err)
        })
    }, [brand])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const onSave = (e) => {
        e.preventDefault()
        toastId.current = toast("Обновляем бренд", { autoClose: false })
        loaders.brands.update(brand)
            .then(() => {
                toast.update(toastId.current, { render: "Бренд обновлен", type: toast.TYPE.INFO, autoClose: 3000 })
            }).catch((err) => {
            console.log(err)
            toast.update(toastId.current, { render: "Не удалось обновить бренд", type: toast.TYPE.ERROR, autoClose: 3000 })
        })
    }

    return brand === null ? (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {
                    ({ data }) => {
                        Promise.resolve(data).then((b) => setBrand(b[0]))
                    }
                }
            </Await>
        </Suspense>
    ) : (
        <>
            <ToastContainer />
            <input type="text" onChange={({target: {value}}) => setBrand({...brand, name: value})} name="name" placeholder="Название" value={brand.name}/>
            <br/>
            <textarea placeholder="Описание" rows={5} onChange={({target: {value}}) => setBrand({...brand, description: value})} value={brand.description} />
            <br/>
            <div className={classes.checkbox}>
                <input type="checkbox" name="showOnMainPage" id="showOnMainPage" defaultChecked={brand.showOnMainPage} onChange={() => setBrand({...brand, showOnMainPage: !brand.showOnMainPage})} />
                <label htmlFor="showOnMainPage">Показывать на главной странице</label>
            </div>
            <img src={brand.imgUrl[0]} alt={brand.name} className={classes.img} />
            <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Перетащите файл сюда</p> :
                        <p>Перетащите файл или кликните на область, чтобы выбрать</p>
                }
            </div>
            <br/>
            <button onClick={onSave} className={classes.btn}>Сохранить</button>
        </>

    )
}

export default Brand