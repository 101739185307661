import {Suspense, useCallback, useRef, useState} from "react"
import {Await, useLoaderData} from "react-router-dom"
import {toast, ToastContainer} from "react-toastify"
import classes from "./Slider.module.css"
import gClasses from "../../../common.module.css"
import {useDropzone} from "react-dropzone"
import Loaders from "../../../loaders"
import clsx from "clsx"

const Slider = () => {

    const loaders = new Loaders()
    const data = useLoaderData()
    const [slider, setSlider] = useState(null)

    const toastId = useRef(null)

    const setValue = (key, value) => {
        setSlider({...slider, [key]: value})
    }

    const setSlideValue = (index, key, value, isAction = false) => {
        let slides = [...slider.slides]


        if (isAction) {
            slides[index]["action"][key] = value
        } else {
            slides[index][key] = value
        }

        setSlider({...slider, slides})
    }

    const onClickAddSlide = (e) => {
        e.preventDefault()
        let slides = slider.slides
        slides.push({img: "", title: "", subtitle: "", action: {url: "", title: "", color: ""}})
        setSlider({...slider, slides})
    }

    const onDrop = useCallback((acceptedFiles, event) => {
        if (rootRef && rootRef.current) {
            console.log(rootRef.current.dataset)
            const index = parseInt(rootRef.current.dataset.slide, 10)
            let slides = slider.slides
            loaders.slider.setImage(slider._id, acceptedFiles[0])
                .then((data) => {
                    slides[index].img = data.urlImg
                    setSlider({...slider, slides})
                }).catch((err) => {
                console.log(err)
            })
        }
    }, [slider])

    const {getRootProps, getInputProps, isDragActive, rootRef} = useDropzone({
        onDrop,
        maxFiles: 1
    })

    const onSave = (e) => {
        e.preventDefault()
        toastId.current = toast("Обновляем слайдер", { autoClose: false })

        slider.id = slider._id
        console.log(slider)
        loaders.slider.update(slider)
            .then(() => {
                toast.update(toastId.current, { render: "Слайдер обновлен", type: toast.TYPE.INFO, autoClose: 3000 })
            })
            .catch((err) => {
                toast.update(toastId.current, { render: "Не удалось обновить слайдер", type: toast.TYPE.ERROR, autoClose: 3000 })
                console.error(err)
            })
    }

    const onDeleteSlide = (e, index) => {
        e.preventDefault()
        let slides = slider.slides.splice(index - 1, 1)
        setSlider({...slider, slides})

        loaders.slider.deleteImage(slider._id, index)
            .then((data) => {
                console.log(data)
            })
            .catch((err) => {
                console.error(err)
            })
    }

    return slider ? (
        <>
            <ToastContainer />
            <h4>Название</h4>
            <input type="text" value={slider.name} onChange={({target:{value}}) => setValue("name", value)}/>

            <h4>Слайды</h4>
            <button onClick={onClickAddSlide} className={clsx(gClasses.btn, gClasses.btnGreen)}>Добавить слайд</button>
            {
                slider.slides.map((slide, i) => (
                    <div key={`slide_${i}`} className={classes.slide}>
                        <div>
                            <img src={slide.img} alt={slide.title || ""} />
                            <div {...getRootProps()} data-slide={i.toString()} className={gClasses.dropzone}>
                                <input {...getInputProps()} />
                                {
                                    isDragActive ?
                                        <p>Перетащите файл сюда</p> :
                                        <p>Перетащите файл или кликните на область, чтобы выбрать</p>
                                }
                            </div>
                        </div>
                        <div className={classes.slideBody}>
                            <input type="text" name="title" onChange={({target:{value}}) => setSlideValue(i, "title", value)} placeholder="Заголовок" value={slide.title}/>
                            <input type="text" name="subtitle" onChange={({target:{value}}) => setSlideValue(i, "subtitle", value)} placeholder="Подзаголовок" value={slide.subtitle}/>
                            <h5>Кнопка действия</h5>
                            <input type="text" name="url" onChange={({target:{value}}) => setSlideValue(i, "url", value, true)} placeholder="Заголовок" value={slide.action.url}/>
                            <input type="text" name="action-title" onChange={({target:{value}}) => setSlideValue(i, "title", value, true)} placeholder="Подзаголовок" value={slide.action.title}/>
                            <input type="text" name="color" onChange={({target:{value}}) => setSlideValue(i, "color", value, true)} placeholder="Цвет" value={slide.action.color}/>
                        </div>
                        <button onClick={(e) => onDeleteSlide(e, i)} className={clsx(gClasses.btn, gClasses.btnRed)}>Удалить слайд</button>
                    </div>
                ))
            }
            <button onClick={onSave} className={clsx(gClasses.btn)}>Сохранить</button>
        </>
    ) : (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {
                    ({data}) => {
                        Promise.resolve(data).then((s) => setSlider(s[0]))
                    }
                }
            </Await>
        </Suspense>
    )
}

export default Slider