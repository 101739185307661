export const getMinPrice = (product) => {
    const modPrice =
        product.modifiers.length > 0
            ? product.modifiers.reduce((price, mod) => {
                  if (mod.priceDefault < price) return mod.priceDefault
                  return price
              }, Number.POSITIVE_INFINITY)
            : 0

    return modPrice + (product.priceDefault || 0)
}
