import Cookies from 'universal-cookie'

// eslint-disable-next-line import/no-anonymous-default-export
export default class {
    cookies = new Cookies()
    token = this.cookies.get('token') || ''
    apiBase = 'https://api.parfum-atelier.com'

    methods = {
        POST: 'POST',
        GET: 'GET',
        PUT: 'PUT',
        PATCH: 'PATCH',
        DELETE: 'DELETE',
    }

    resource = async (
        path,
        method = this.methods.GET,
        body = {},
        contentType = 'application/json'
    ) => {
        const headers = {
            'Content-Type': contentType,
            Authorization: `Bearer ${this.token}`,
            Accept: 'application/json',
        }

        if (contentType === '') {
            delete headers['Content-Type']
        }

        const res = await fetch(`${this.apiBase}${path}`, {
            method,
            headers,
            body: method === this.methods.GET ? undefined : JSON.stringify(body),
        })

        if (!res.ok) {
            if (res.status === 401 && this.token) {
                window.location.href = '/login'
            }
            throw new Error(`Could not fetch ${path}, received ${res.status}`)
        }

        if (res.status !== 204) return await res.json()
    }

    upload = async (path, image, params = null) => {
        const formData = new FormData()
        formData.append('file', image, image.name)

        if (params) {
            for (const [key, value] of Object.entries(params)) {
                formData.append(key, value)
            }
        }

        const res = await fetch(`${this.apiBase}${path}`, {
            method: this.methods.PATCH,
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
            body: formData,
        })

        if (res.status === 403 || res.status === 401) window.location.href = '/login'

        if (!res.ok) throw new Error(`Could not upload image, received ${res.status}`)

        return res.json()
    }

    auth = async (login, password) => {
        return await this.resource('/v1/auth/loginAdmin', this.methods.POST, {
            login,
            password,
        })
    }

    /* [snippet:bundles] */
    bundles = {
        get: async (limit = 50, offset = 0) => {
            return await this.resource(
                `/v1/bundle/get?limit=${limit}&offset=${offset}`,
                this.methods.POST,
                {}
            )
        },
        getById: async (id) => {
            return await this.resource(`/v1/bundle/get?_id=${id}`, this.methods.POST, {})
        },
        search: async (text, limit = 50, offset = 0) => {
            return await this.resource(
                `/v1/bundle/get?name=${text}&limit=${limit}&offset=${offset}`,
                this.methods.POST,
                {}
            )
        },
        setImage: async (id, image) => {
            return await this.upload(`/v1/bundle/image?_id=${id}`, image)
        },
        update: async (bundle) => {
            return await this.resource(`/v1/bundle`, this.methods.PATCH, bundle)
        },
        deleteImage: async (_id, url) => {
            return await this.resource(`/v1/bundle/image`, this.methods.DELETE, { _id, url })
        },
        delete: async (_id, isDeleted) => {
            return await this.resource('/v1/bundle', this.methods.PATCH, {
                _id,
                isDeleted,
            })
        },
    }
    /* [/snippet:bundles] */

    /* [snippet:products] */
    products = {
        get: async (limit = 50, offset = 0) => {
            return await this.resource(
                `/v1/product/get?limit=${limit}&offset=${offset}&isDeleted=all&isDisabled=all`,
                this.methods.POST,
                {}
            )
        },
        getById: async (id) => {
            return await this.resource(
                `/v1/product/get?_id=${id}&isDeleted=all&isDisabled=all`,
                this.methods.POST,
                {}
            )
        },
        search: async (text, limit = 50, offset = 0) => {
            return await this.resource(
                `/v1/product/get?name=${text}&limit=${limit}&offset=${offset}&isDeleted=all&isDisabled=all`,
                this.methods.POST,
                {}
            )
        },
        setImage: async (id, image) => {
            return await this.upload(`/v1/product/image?_id=${id}`, image)
        },
        update: async (product) => {
            return await this.resource(`/v1/product`, this.methods.PATCH, product)
        },
        deleteImage: async (_id, url) => {
            return await this.resource(`/v1/product/image`, this.methods.DELETE, { _id, url })
        },
        disable: async (_id, isDisabled) => {
            return await this.resource('/v1/product', this.methods.PATCH, {
                _id,
                isDisabled,
            })
        },
        delete: async (_id, isDeleted) => {
            return await this.resource('/v1/product', this.methods.PATCH, {
                _id,
                isDeleted,
            })
        },
    }
    /* [/snippet:products] */

    /* [snippet:brands] */
    brands = {
        get: async (limit = 25, offset = 0) => {
            return await this.resource(
                `/v1/brand?limit=${limit}&offset=${offset}`,
                this.methods.GET
            )
        },
        getById: async (id) => {
            return await this.resource(`/v1/brand?_id=${id}`, this.methods.GET)
        },
        add: async (name, showOnMainPage) => {
            return await this.resource(`/v1/brand/create`, this.methods.POST, {
                name,
                showOnMainPage,
            })
        },
        update: async (brand) => {
            return await this.resource(`/v1/brand`, this.methods.PATCH, brand)
        },
        setImage: async (id, image) => {
            return await this.upload(`/v1/brand/image?_id=${id}`, image)
        },
        delete: async (_id, isDeleted) => {
            return await this.resource('/v1/brand', this.methods.PATCH, {
                _id,
                isDeleted,
            })
        },
    }
    /* [/snippet:brands] */

    /* [snippet:tags] */
    tags = {
        get: async (limit = 100, offset = 0, group = null) => {
            const url = group
                ? `/v1/tag?limit=${limit}&offset=${offset}&group=${group}&isDeleted=all&isDisabled=all`
                : `/v1/tag?limit=${limit}&offset=${offset}`
            return await this.resource(url, this.methods.GET)
        },
        getGroup: async (limit = 100, offset = 0) => {
            return await this.resource(
                `/v1/tagGroup?limit=${limit}&offset=${offset}&isDeleted=all&isDisabled=all`,
                this.methods.GET
            )
        },
        add: async (name, group) => {
            return await this.resource(`/v1/tag/create`, this.methods.POST, {
                name,
                groups: [group],
            })
        },
        addGroup: async (name) => {
            return await this.resource(`/v1/tagGroup/create`, this.methods.POST, { name })
        },
        // deleteGroup: async (_id) => {
        //     return await this.resource("/v1/tagGroup", this.methods.PATCH, {_id, isDeleted: true})
        // },
        delete: async (_id, isDeleted) => {
            return await this.resource('/v1/tag', this.methods.PATCH, {
                _id,
                isDeleted,
            })
        },
    }
    /* [/snippet:tags] */

    /* [snippet:tags] */
    tagsGroup = {
        get: async (limit = 100, offset = 0) => {
            return await this.resource(
                `/v1/tagGroup?limit=${limit}&offset=${offset}&isDeleted=all&isDisabled=all`,
                this.methods.GET
            )
        },
        // deleteGroup: async (_id) => {
        //     return await this.resource("/v1/tagGroup", this.methods.PATCH, {_id, isDeleted: true})
        // },
        delete: async (_id, isDeleted) => {
            return await this.resource('/v1/tagGroup', this.methods.PATCH, {
                _id,
                isDeleted,
            })
        },
    }
    /* [/snippet:tags] */

    /* [snippet:tags] */
    categories = {
        get: async (limit = 100, offset = 0) => {
            return await this.resource(
                `/v1/category?limit=${limit}&offset=${offset}`,
                this.methods.GET
            )
        },
        add: async (name, description) => {
            return await this.resource(`/v1/category/create`, this.methods.POST, {
                name,
                description,
            })
        },
        search: async (text, limit = 50, offset = 0) => {
            return await this.resource(
                `/v1/category?name=${text}&limit=${limit}&offset=${offset}`,
                this.methods.POST,
                {}
            )
        },
        delete: async (_id, isDeleted) => {
            return await this.resource('/v1/category', this.methods.PATCH, {
                _id,
                isDeleted,
            })
        },
    }
    /* [/snippet:tags] */

    /* [snippet:tags] */
    badges = {
        get: async (limit = 100, offset = 0) => {
            return await this.resource(
                `/v1/badge?limit=${limit}&offset=${offset}`,
                this.methods.GET
            )
        },
        add: async () => {},
        delete: async (_id) => {
            return await this.resource('/v1/badge', this.methods.PATCH, {
                _id,
                isDeleted: true,
            })
        },
    }
    /* [/snippet:tags] */

    /* [snippet:slider] */
    slider = {
        get: async (id = null) => {
            let path = '/v1/slider'
            if (id !== null) path += `?_id=${id}`

            return await this.resource(path, this.methods.GET)
        },
        update: async (body) => {
            return await this.resource('/v1/slider', this.methods.PATCH, body)
        },
        setImage: async (_id, image) => {
            return await this.upload(`/v1/slider/preview?_id=${_id}`, image)
        },
        deleteImage: async (_id, index) => {
            return await this.resource('/v1/slider/image', this.methods.DELETE, { _id, index })
        },
    }
    /* [/snippet:slider] */

    /* [snippet:events] */
    events = {
        add: async () => {
            return await this.resource('/v1/Sale/create', this.methods.POST, {
                title: `Название акции ${(Math.random() + 1).toString(36).substring(4)}`,
                content: '',
                desc: '',
                link: '',
                validity: {
                    from: new Date(),
                    until: '',
                },
            })
        },
        get: async (id = null) => {
            let path = '/v1/Sale'
            if (id !== null) path += `?_id=${id}`

            return await this.resource(path, this.methods.GET)
        },
        notify: async (id, customerId = null) => {
            return await this.resource('/v1/Sale/notify', this.methods.POST, { id, customerId })
        },
        update: async (body) => {
            return await this.resource('/v1/Sale', this.methods.PATCH, body)
        },
        setImage: async (_id, image) => {
            return await this.upload(`/v1/Sale/image?_id=${_id}`, image)
        },
        deleteImage: async (_id, index) => {
            return await this.resource('/v1/slider/image', this.methods.DELETE, { _id, index })
        },
    }
    /* [/snippet:events] */

    stories = {
        add: async () => {
            return await this.resource('/v1/story/create', this.methods.POST, {
                name: `Название истории ${(Math.random() + 1).toString(36).substring(4)}`,
                isDisabled: true,
                isDeleted: true,
            })
        },
        get: async (id = null) => {
            let path = '/v1/story'
            if (id !== null) path += `?_id=${id}`

            return await this.resource(path, this.methods.GET)
        },
        update: async (body) => {
            console.log(body)
            return await this.resource('/v1/story', this.methods.PATCH, body)
        },
        setImage: async (_id, image) => {
            return await this.upload(`/v1/story/image?_id=${_id}`, image)
        },
        deleteImage: async (_id, index) => {
            return await this.resource('/v1/story/image', this.methods.DELETE, { _id, index })
        },
    }

    /* [snippet:sync] */
    sync = {
        common: async (type = 'full') => {
            return await this.resource(`/v1/sync?type=${type}`, this.methods.PUT, {})
        },
    }
    /* [/snippet:sync] */

    /* [snippet:push] */
    notifications = {
        get: async (id = null) => {
            let q = '/v1/Notification'
            if (id) {
                q += `?_id=${id}`
            }
            return await this.resource(q, this.methods.GET)
        },
        delete: async (_id) => {
            return await this.resource('/v1/Notification', this.methods.DELETE, {
                _id,
                isDeleted: true,
            })
        },
        create: async (body) => {
            return await this.resource('/v1/Notification/create', this.methods.POST, body)
        },
        update: async (body) => {
            return await this.resource('/v1/Notification', this.methods.PATCH, body)
        },
    }
    /* [/snippet:push] */
}
