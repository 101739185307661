import { Outlet, useLocation } from 'react-router-dom'
import Stories from './Stories'

const Main = () => {
    const location = useLocation()

    return (
        <>
            {location.pathname === '/contents/stories' && <Stories />}
            <Outlet />
        </>
    )
}

export default Main
