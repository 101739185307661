import {Suspense} from "react"
import Button from "../../../Components/Button"
import Table from "../../../Components/Table"
import {Await, useLoaderData} from "react-router-dom"
import Loaders from "../../../loaders"

const Brands = () => {

    const brands = useLoaderData()
    const loaders = new Loaders()

    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={brands} errorElement={<p>Error</p>}>
                {
                    res => (
                        <Table api={loaders.brands} withSearch withButtons={buttons} columns={columns} data={res} path="/store/brands">

                        </Table>
                    )
                }
            </Await>
        </Suspense>
    )
}

const buttons = [
    <Button key="btn_add_brand" name="Добавить бренд" link="add" />
]

const columns = [
    {
        name: "Название",
        field: "name"
    },
    {
        name: "Дата создания",
        field: "updatedAt",
        type: "date"
    }
]

export default Brands