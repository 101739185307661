import Eye from '../../assets/eye.png'
import CrossedEye from '../../assets/crossed-out-eye.png'
import classes from './Button.module.css'

const DisableButton = ({ id, action, complete, isDisabled, onDisableByFunction }) => {
    const onClick = (e) => {
        e.preventDefault()
        console.log(action)
        if (onDisableByFunction) {
            onDisableByFunction()
        } else {
            action(id, !isDisabled)
                .then((data) => {
                    console.log(data)
                    complete(true)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <button className={classes.btnImage} onClick={onClick}>
            <img src={isDisabled ? CrossedEye : Eye} alt='Отключить' />
        </button>
    )
}

export default DisableButton
