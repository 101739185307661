import {useNavigate} from "react-router-dom"
import classes from "./Button.module.css"

const Button = ({name, link, image, action}) => {

    const navigate = useNavigate()

    const onClick = (e) => {
        e.preventDefault()
        navigate(link)
    }

    if (action) {
        return (
            <button className={classes.btnImage}>
                <img src={ image } alt={name} />
            </button>
        )
    } else {
        return (
            <button className={classes.btn} onClick={ onClick }>{name}</button>
        )
    }
}

export default Button