import {useState} from "react"
import Loaders from "../../../loaders"

const Add = () => {

    const loaders = new Loaders()
    const [name, setName] = useState("")
    const [show, setShow] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        loaders.brands.add(name, show).then((data) => {
            console.log(data)
            setName("")
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <form onSubmit={ onSubmit }>
            <input type="text" value={name} onChange={({target:{value}}) => setName(value)} placeholder="Название бренда" required />
            <div>
                <input type="checkbox" id="show-on-main-page" onChange={() => setShow(!show)} defaultChecked={show} />
                <label htmlFor="show-on-main-page">Показывать на главном экране</label>

            </div>
            <button type="submit">Добавить</button>
        </form>
    )
}

export default Add