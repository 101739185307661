import React, { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Button from '../../Components/Button'
import Table from '../../Components/Table'
import Loaders from '../../loaders'

export const NotificationsTable = () => {
    const pushes = useLoaderData()
    const loaders = new Loaders()

    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={pushes} errorElement={<p>Error</p>}>
                {(res) => (
                    <Table
                        api={loaders.notifications}
                        withSearch
                        withButtons={buttons}
                        columns={columns}
                        data={res}
                        path='/notifications'
                    />
                )}
            </Await>
        </Suspense>
    )
}

const buttons = [
    <Button key='btn_add_push' name='Добавить уведомление' link='add' />,
]

const columns = [
    {
        name: 'Название',
        field: 'title',
    },
    {
        name: 'Дата',
        field: 'untilDate',
        type: 'date',
    },
]
