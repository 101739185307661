import {Suspense} from "react"
import {Await, useLoaderData} from "react-router-dom"
import Table from "../../../Components/Table"
import Button from "../../../Components/Button"
import Loaders from "../../../loaders"

const Categories = () => {

    const tags = useLoaderData()
    const loaders = new Loaders()

    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={tags} errorElement={<p>Error</p>}>
                {
                    res => (
                        <Table api={loaders.categories} withSearch withButtons={buttons} columns={columns} data={res}>

                        </Table>
                    )
                }
            </Await>
        </Suspense>
    )
}

const buttons = [
    <Button key="btn_add_category" name="Добавить категорию" link="add" />
]

const columns = [
    {
        name: "Название",
        field: "name"
    },
    {
        name: "Дата обновления",
        field: "updatedAt",
        type: "date"
    }
]

export default Categories