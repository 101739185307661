import React, {Suspense, useEffect, useState} from 'react'
import {Await, useLoaderData, useNavigate} from 'react-router-dom'
import Select from "react-select";
import classes from "../../Components/Button/Button.module.css";
import Loaders from "../../loaders"

export const AddNotification = () => {
    const data = useLoaderData()
    const loaders = new Loaders()

    const [saleId, setSaleId] = useState('')
    const [customerId, setCustomerId] = useState("")
    const [sales, setSales] = useState(null)

    const onSaleChange = ({value}) => {
        setSaleId(value)
    }

    const onSubmitHandler = (e) => {
        e.preventDefault()
        console.log(saleId, customerId)
        loaders.events.notify(saleId, customerId !== "" ? customerId : null)
            .then((data) => {
                alert(`Success ${data.successCount}. Failure ${data.failureCount}`);
            }).catch((err) => {
                console.log(err.message)
            })
    }

    const prepareValues = (b, func) => {
        let bs = []
        for (let i of b) {
            bs.push({value: i._id, label: i.title})
        }

        func(bs)
    }

    return sales === null ? (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {
                    ({data}) => {
                        Promise.resolve(data).then((b) => prepareValues(b, setSales))
                    }
                }
            </Await>
        </Suspense>
    ) : (
        <form onSubmit={onSubmitHandler}>
            <Select onChange={onSaleChange} isSearchable isLoading={sales.length === 0} options={sales}/>
            <br/>
            CustomerID
            <input type="text" value={ customerId } onChange={({target:{value}}) => setCustomerId(value)} />
            <br/>
            <button type='submit' className={classes.btn}>Отправить PUSH</button>
        </form>
    )
}
