import classes from "./Login.module.css"
import {Link, useNavigate} from "react-router-dom"
import {useEffect, useRef, useState} from "react"
import {useCookies} from "react-cookie"
import Loaders from "../../loaders"
import {DateTime} from "luxon"
import clsx from "clsx"

const Login = () => {

    const navigate = useNavigate()

    const btn = useRef()

    const [cookies, setCookie] = useCookies(["token"])
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [isLockedBtn, setIsLockedBtn] = useState(false)

    useEffect(() => {
        if (cookies.token) {
            navigate("/")
        }
    }, [])

    useEffect(() => {
        if (btn.current) {
                btn.current.disabled = isLockedBtn
        }
    }, [isLockedBtn])

    const onSubmit = (e) => {
        e.preventDefault()
        setIsLockedBtn(true)

        if (login.length > 0 && password.length > 0) {
            const api = new Loaders()
            api.auth(login, password)
                .then(({ token }) => {
                    if (token) {
                        const dt = DateTime.now().plus({days: 7})
                        setCookie(
                            "token",
                            token,
                            {path: "/", secure: true, expires: dt.toJSDate()})
                        navigate("/")
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLockedBtn(false)
                })
        }
    }

    return (
        <main className={classes.login}>
            <div className={classes.login__container}>
                <div className={classes.login__container__left}>
                    <img src="https://storage.yandexcloud.net/parfum.public/wallet/2741cdd2-9885-42eb-a6a8-dad33eb38115.png" alt="Parfumer Atelier" />
                </div>
                <div className={classes.login__container__right}>
                    <h1>Вход</h1>
                    <form onSubmit={ onSubmit } className={classes.login__container__form}>
                        <input type="text" placeholder="Логин" onChange={ ({target:{value}}) => setLogin(value) } value={ login } required />
                        <input type="password" placeholder="Пароль" onChange={ ({target:{value}}) => setPassword(value) } value={ password } required />
                        <button type="submit" className={clsx("", {[classes.btn__disabled]: isLockedBtn})} ref={ btn }>Войти</button>
                        <Link to="forget-password">Забыли пароль?</Link>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Login