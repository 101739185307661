import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import classes from './Table.module.css'
import gClasses from '../../common.module.css'
import Button from '../Button'
import Eye from '../../assets/eye.svg'
import CrossedOutEye from '../../assets/crossed-out-eye.svg'
import clsx from 'clsx'
import DeleteButton from '../Button/DeleteButton'
import DisableButton from "../Button/DisableButton";

const Table = ({
    withSearch,
    api,
    withButtons,
    withPagination,
    columns,
    data,
    path,
    onDelete,
    onDisable,
    removed,
    rowModifierFunc,
}) => {
    const [searchParams] = useSearchParams()

    const [search, setSearch] = useState('')
    const [resp, setResp] = useState(null)
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(0)
    const [complete, setComplete] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const p = parseInt(searchParams.get('page'), 10)

        if (p) {
            setPage(p)
        } else {
            setPage(1)
        }

        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        if (complete) window.location.reload()
    }, [complete])

    useEffect(() => {
        if (data) {
            Promise.resolve(data.data).then((d) => {
                const keys = Object.keys(d)
                const items = keys.includes('values') ? [...d.values] : [...d]

                const filtered = items.filter(
                    (obj) => (removed ? !removed.includes(obj._id) : true) && !obj.isDeleted
                )
                if (!withPagination) {
                    setResp({ values: filtered })
                } else {
                    const count = keys.includes('count') ? d.count : 0
                    setResp({ values: filtered, count })
                    setPages(Math.ceil(count / 50))
                }
            })
        }
    }, [data, removed])

    const onRowClick = (e, id) => {
        e.preventDefault()
        navigate(`${path}/${id}`)
    }

    const onSearch = (e) => {
        e.preventDefault()
        navigate(`?s=${search}`)
    }

    return (
        <>
            <div className={classes.tableHeader}>
                {withSearch ? (
                    <form onSubmit={onSearch} className={classes.tableHeader_search}>
                        <input
                            type='search'
                            placeholder='Поиск'
                            value={search}
                            onChange={({ target: { value } }) => setSearch(value)}
                        />
                        <button type='submit'>Найти</button>
                    </form>
                ) : null}

                {Array.isArray(withButtons) && withButtons ? (
                    <div className={classes.tableHeader_buttons}>{withButtons.map((b) => b)}</div>
                ) : null}
            </div>
            {resp === null ? (
                <p>Загружаем</p>
            ) : resp.values.length === 0 ? (
                <p>Упс!!! Пока тут ничего нет</p>
            ) : (
                <>
                    <table className={classes.table}>
                        <thead>
                            <tr>
                                {Array.isArray(columns) &&
                                    columns.map((row, i) => (
                                        <th key={`th_row_${i}`}>{row.name}</th>
                                    ))}
                                <td>...</td>
                            </tr>
                        </thead>
                        <tbody>
                            {(rowModifierFunc ? rowModifierFunc(resp.values) : resp.values).map(
                                (row, i) => (
                                    <tr
                                        className={clsx({ [classes.isDeleted]: row.isDeleted })}
                                        key={`tr_row_${i}`}>
                                        {columns.map((c, j) => {
                                            // let field = c.field.split(".").reduce((a, b) => a[b], row)
                                            let field = row[c.field]

                                            switch (c.type) {
                                                case 'date':
                                                    field =
                                                        DateTime.fromISO(field).toFormat(
                                                            'dd.MM.yy HH:mm'
                                                        )
                                                    break
                                                case 'price':
                                                    field /= 1
                                                    field = field
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                                                    field += '₽'
                                                    break
                                                default:
                                                    break
                                            }

                                            return (
                                                <td
                                                    key={`td_c_${j}`}
                                                    onClick={
                                                        j !== columns.length - 1
                                                            ? (e) => onRowClick(e, row._id)
                                                            : null
                                                    }>
                                                    {field}
                                                </td>
                                            )
                                        })}
                                        <td style={{ display: 'flex' }}>
                                            <DisableButton
                                                isDisabled={row.isDisabled}
                                                complete={setComplete}
                                                id={row._id}
                                                onDeleteByFunction={
                                                    onDisable ? () => onDisable(row) : undefined
                                                }
                                                action={api && api.disable}
                                            />
                                            <DeleteButton
                                                isDeleted={row.isDeleted}
                                                complete={setComplete}
                                                id={row._id}
                                                onDeleteByFunction={
                                                    onDelete ? () => onDelete(row) : undefined
                                                }
                                                action={api && api.delete}
                                            />
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                    {withPagination ? (
                        <div className={classes.pagination}>
                            <p>Всего: {resp.count}</p>
                            <div className={classes.pages}>
                                <ul>
                                    <li className={clsx(classes.page)}>
                                        <Link
                                            to={`?${search !== '' ? `s=${search}&` : ''}page=${1}`}
                                        />
                                    </li>
                                    {[...Array(pages).keys()].map((p) => (
                                        <li
                                            key={`page${p + 1}`}
                                            className={clsx(classes.page, {
                                                [classes.active]: p + 1 === page,
                                            })}>
                                            <Link
                                                to={`?${search !== '' ? `s=${search}&` : ''}page=${
                                                    p + 1
                                                }`}
                                            />
                                        </li>
                                    ))}

                                    <li className={clsx(classes.page)}>
                                        <Link
                                            to={`?${
                                                search !== '' ? `s=${search}&` : ''
                                            }page=${pages}`}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ) : null}
                </>
            )}
        </>
    )
}

export default Table
