import React from 'react'
import { Header } from '../../Components/Contetnt'
import Page from '../../Components/Page'
import { Outlet } from 'react-router-dom'
export const Notifications = () => {
    return (
        <Page>
            <Header title='Push-уведомления' />
            <Outlet />
        </Page>
    )
}
