import {Suspense} from "react"
import {Await, useLoaderData} from "react-router-dom"
import Table from "../../../Components/Table"
import Loaders from "../../../loaders";

const Bundles = () => {

    const data = useLoaderData()
    const loaders = new Loaders()

    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {
                    res => (
                        <Table api={loaders.bundles} withSearch withPagination columns={columns} data={res} path="/store/bundles" />
                    )
                }
            </Await>
        </Suspense>
    )
}

const columns = [
    {
        name: "Артикул",
        field: "article"
    },
    {
        name: "Название",
        field: "name"
    },
    {
        name: "Цена",
        field: "priceDefault",
        type: "price"
    },
    {
        name: "Дата обновления",
        field: "updatedAt",
        type: "date"
    },
]

export default Bundles