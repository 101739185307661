import {useState} from "react"
import Loaders from "../../../loaders"

const AddGroup = () => {

    const loaders = new Loaders()
    const [name, setName] = useState("")

    const onSubmit = (e) => {
        e.preventDefault()
        loaders.tags.addGroup(name).then((data) => {
            console.log(data)
            setName("")
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <form onSubmit={ onSubmit }>
            <input type="text" value={name} onChange={({target:{value}}) => setName(value)} placeholder="Название группы" required />
            <button type="submit">Добавить</button>
        </form>
    )
}

export default AddGroup