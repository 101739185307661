import { Suspense, useState } from 'react'
import { Await, useLoaderData } from 'react-router-dom'
import Table from '../../../Components/Table'
import Button from '../../../Components/Button'
import Loaders from '../../../loaders'

const Events = () => {
    const data = useLoaderData()
    const loaders = new Loaders()
    const [removed, setRemoved] = useState([])
    const removeEvent = (event) => {
        loaders.events
            .update({
                ...event,
                isDeleted: true,
            })
            .then((res) => {
                setRemoved((prev) => [...prev, event._id])
            })
            .catch((e) => {
                console.log(e)
            })
    }
    return (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {(res) => (
                    <Table
                        onDelete={removeEvent}
                        withButtons={buttons}
                        columns={columns}
                        removed={removed}
                        data={res}
                        path='/contents/events'
                    />
                )}
            </Await>
        </Suspense>
    )
}

const buttons = [<Button key='btn_add_slider' name='Добавить акцию' link='add' />]

const columns = [
    {
        name: 'Название',
        field: 'title',
    },
    {
        name: 'Дата обновления',
        field: 'updatedAt',
        type: 'date',
    },
]

export default Events
