import { useEffect } from "react"

const Compilations = () => {

    useEffect(() => {
        
    }, [])

    return (
        <p>Compilations</p>
    )
}

export default Compilations