import Page from "../../Components/Page"

const Customers = () => {
    return (
        <Page>
            <div>Customers</div>
        </Page>
    )
}

export default Customers