import {Outlet, useLocation} from "react-router-dom"
import Tags from "./Tags"

const Main = () => {

    const location = useLocation()

    return (
        <>
            {
                location.pathname === "/store/tags" ? (
                    <Tags />
                ) : null
            }
            <Outlet />
        </>
    )
}

export default Main