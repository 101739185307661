import Aside from "../Aside"
import Header from "../Header"
import classes from "./Page.module.css"
import {useCookies} from "react-cookie";
import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const Page = ({ children }) => {

    const location = useLocation()
    const navigate = useNavigate()

    const [cookies] = useCookies(["token"])

    useEffect(() => {
        if (typeof cookies.token === "undefined") {
            navigate("/login")
        }
    }, [location, cookies.token])

    return (
        <main className={classes.page}>
            <Aside />
            <Header />
            <div className={classes.page__content}>
                { children }
            </div>
        </main>
    )
}

export default Page