import Page from "../../Components/Page"

const NotFound = () => {

    return (
        <Page>
            <div>NotFound</div>
        </Page>
    )
}

export default NotFound