import {Outlet, useLocation} from "react-router-dom"
import Bundles from "./Bundles"

const Main = () => {

    const location = useLocation()

    return (
        <>
            {
                location.pathname === "/store/bundles" ? (
                    <Bundles />
                ) : null
            }
            <Outlet />
        </>
    )
}

export default Main