import {Await, useLoaderData} from "react-router-dom"
import Select from "react-select"
import {createRef, Suspense, useCallback, useEffect, useRef, useState} from "react"
import classes from "../../../common.module.css"
import {useDropzone} from "react-dropzone"
import Loaders from "../../../loaders"
import DeleteIcon from "../../../assets/delete.svg"
import PlusIcon from "../../../assets/plus.svg"
import clsx from "clsx"
import {toast, ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertToRaw, convertFromHTML } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"

const Bundle = () => {

    const data = useLoaderData()

    const [bundle, setBundle] = useState(null)
    const [editor, setEditor] = useState(EditorState.createEmpty())
    const [brands, setBrands] = useState(null)
    const [tags, setTags] = useState(null)
    const [categories, setCategories] = useState(null)
    const [badges, setBadges] = useState(null)

    const loaders = new Loaders()

    const refs = useRef(Array.from({length: 20}, () => createRef()))
    const gridRef = useRef(null)
    const toastId = useRef(null)

    useEffect(() => {
        if (bundle && bundle.description)
            setEditor(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(convertFromHTML(bundle.description))
                )
            )
    }, [bundle])

    const onDragStart = (e) => {
        e.currentTarget.classList.add("selected")
    }

    const onDragEnd = (e) => {
        e.currentTarget.classList.remove("selected")
    }

    const getNextEl = (position, el) => {
        const coords = el.getBoundingClientRect()
        const center = coords.x + coords.width / 2
        return (position < center) ? el : el.nextElementSibling
    }

    const onDragOver = (e) => {
        e.preventDefault()
        const active = refs.current.find(el => el.current.classList.contains("selected"))
        const current = e.currentTarget
        const isMovable = active !== current && current.classList.contains("image")

        if (!isMovable) {
            return false
        }

        const next = getNextEl(e.clientX, current)

        if (next && (active.current === next.previousElementSibling || active.current === next)) {
            return false
        }

        if (gridRef.current) {
            gridRef.current.insertBefore(active.current, next)
        }
    }

    const onDrop = useCallback(acceptedFiles => {
        loaders.bundles.setImage(bundle._id, acceptedFiles[0])
            .then((data) => {
                const urls = bundle.imgUrl
                urls.push(data.imgUrl)
                setBundle({...bundle, imgUrl: urls})
            }).catch((err) => {
            console.log(err)
        })
    }, [bundle])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const prepareValues = (b, func) => {
        let bs = []
        for (let i of b) {
            bs.push({value: i._id, label: i.name})
        }

        func(bs)
    }

    const defaultValues = (key, arr) => {
        if (arr && bundle && Array.isArray(bundle[key])) {
            const a = arr.filter((b) => {
                return bundle[key].some((f) => f._id === b.value)
            })
            return a
        }
        return  []
    }

    const setBundleValue = (key, value) => {
        setBundle({...bundle, [key]: value})
    }

    const onBrandChange = ({value}) => {
        setBundle({...bundle, brands: [value]})
    }

    const onTagsChange = (tags) => {
        setBundle({...bundle, tags: tags.map(b => b.value)})
    }

    const onBadgesChange = (badges) => {
        setBundle({...bundle, badges: badges.map(b => b.value)})
    }

    const onCategoryChange = ({value}) => {
        setBundle({...bundle, categories: [value]})
    }

    const onSave = (e) => {
        e.preventDefault()
        toastId.current = toast("Обновляем продукт", { autoClose: false })

        const newImgUrls = []
        if (gridRef.current) {
            for (let child of gridRef.current.children) {
                const url = child.dataset.url
                if (url) {
                    newImgUrls.push(url)
                }
            }
        }

        bundle.imgUrl = newImgUrls
        bundle.description = draftToHtml(convertToRaw(editor.getCurrentContent()))

        loaders.bundles.update(bundle)
            .then(() => {
                toast.update(toastId.current, { render: "Продукт обновлен", type: toast.TYPE.INFO, autoClose: 3000 })
            }).catch((err) => {
                console.log(err)
                toast.update(toastId.current, { render: "Не удалось обновить продукт", type: toast.TYPE.ERROR, autoClose: 3000 })
            })
    }

    const onDelete = (e, url) => {
        e.preventDefault()
        toastId.current = toast("Удаляем изображение", { autoClose: false })
        setBundle({...bundle, imgUrl: bundle.imgUrl.filter(u => u !== url)})
        loaders.bundles.deleteImage(bundle._id, url)
            .then(() => {
                toast.update(toastId.current, { render: "Изображение удалено", type: toast.TYPE.INFO, autoClose: 3000 })
            }).catch((err) => {
                console.log(err)
                toast.update(toastId.current, { render: "Не удалось удалить изображение", type: toast.TYPE.ERROR, autoClose: 3000 })
            })
    }

    return (bundle === null || brands === null || badges === null || tags === null || categories === null) ? (
        <Suspense fallback={<p>loading</p>}>
            <Await resolve={data} errorElement={<p>Error</p>}>
                {
                    ({ data, brands, tags, categories, badges }) => {
                        Promise.resolve(brands).then((b) => prepareValues(b, setBrands))
                        Promise.resolve(badges).then((b) => prepareValues(b, setBadges))
                        Promise.resolve(tags).then((b) => prepareValues(b, setTags))
                        Promise.resolve(categories).then((b) => prepareValues(b, setCategories))
                        Promise.resolve(data).then((b) => setBundle(b.values[0]))
                    }
                }
            </Await>
        </Suspense>
    ) : (
        <>
            <ToastContainer />
            <h4>Название</h4>
            <input type="text" value={bundle.name} onChange={({target:{value}}) => setBundleValue("name", value)}/>

            <h4>Описание</h4>
            <Editor
                editorState={editor}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                localization={{
                    locale: 'ru',
                }}
                onEditorStateChange={(e) => setEditor(e)}
            />

            <h4>Бренд</h4>
            <Select onChange={onBrandChange} isSearchable isLoading={ brands.length === 0 } options={brands} defaultValue={bundle.brands[0] && brands.find((b) => b.value === bundle.brands[0]._id)} />

            <h4>Категории</h4>
            <Select onChange={onCategoryChange} isSearchable isLoading={ categories.length === 0 } options={categories} defaultValue={bundle.categories[0] && categories.find((b) => b.value === bundle.categories[0]._id)} />

            <h4>Тэги</h4>
            <Select onChange={onTagsChange} isMulti isSearchable isLoading={ tags.length === 0 } options={tags} defaultValue={defaultValues("tags", tags)} />

            <h4>Баджи</h4>
            <Select onChange={onBadgesChange} isMulti isSearchable isLoading={ badges.length === 0 } options={badges} defaultValue={defaultValues("badges", badges)} />

            <h4>Изображения<br/><small>Максимум 20 изображений. Первое изображение будет превью</small></h4>
            <div ref={gridRef} className={classes.imagesGrid}>
                {
                    bundle.imgUrl.map((img, i) => (
                        <div
                            key={`img_${i}`}
                            ref={refs.current[i]}
                            draggable
                            data-url={img}
                            className="image"
                            onDragOver={(e) => onDragOver(e)}
                            onDragEnd={onDragEnd}
                            onDragStart={onDragStart}
                        >
                            <img src={img} onDragStart={() => false} alt={i.toString()} />
                            <button onClick={(e) => onDelete(e, img)}>
                                <img src={DeleteIcon} alt="Удалить"/>
                            </button>
                        </div>
                    ))
                }
                <div {...getRootProps()} className={clsx(classes.dropzone, {[classes.dragActive]: isDragActive})}>
                    <input {...getInputProps()} />
                    <img src={PlusIcon} alt="Добавить изображение" />
                </div>
            </div>

            <button onClick={onSave} className={classes.btn}>Сохранить</button>
        </>
    )
}

export default Bundle