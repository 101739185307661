import {Outlet, useLocation} from "react-router-dom"
import Categories from "./Categories"

const Main = () => {

    const location = useLocation()

    return (
        <>
            {
                location.pathname === "/store/categories" ? (
                    <Categories />
                ) : null
            }
            <Outlet />
        </>
    )
}

export default Main